import "../../styles/style.css";
import React from "react";
import Guillaume from "../../assets/guillaumenb.png";
import Pierre from "../../assets/pierrenb.png";
import { RiMailOpenFill } from "react-icons/ri";
import { MdPlace } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";

function Contact() {

  return (
    <>
      <div className="title center" id="besoins">
        <p>Contactez-nous</p>
      </div>
      <div className="article">
        <p style={{ textAlign: "center", fontSize: "1.2em" }}>
          <MdPlace
            className=" "
            style={{
              textAlign: "center",
              marginRight: "4px",
              fontSize: "1.3em",
              color: "#64a369",
            }}
          />
          PractiGREEN SAS
          <br /> 10 rue Victor Hugo <br />
          33400 TALENCE
          <br /> <br />
          <a
            href="mailto:contact@practigreen.fr?subject=Contact PractiGREEN"
            style={{ color: "#64a369" }}
          >
            <RiMailOpenFill
              className=" "
              style={{
                textAlign: "center",
                marginRight: "4px",
                fontSize: "1.3em",
              }}
            />
            contact@practigreen.fr
          </a>{" "}
          <br />
        </p>

        <div
          className="nous "
          style={{ marginBottom: "40px" }}
        >
          <div className="contactez center column second glasmorphism ">
            <img
              src={Pierre}
              alt="Photo de Pierre Véquaud"
              style={{ height: "150px", width: "auto" }}
            />
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              Pierre Véquaud, Président de PractiGREEN <br /> Docteur en
              Sciences Environnementales​ <br /> Expert des enjeux
              environnementaux et climatiques​
              <br /> <br />
              <a href="tel:+33642337740" style={{ textDecoration: "none" }}>
                <BsFillTelephoneFill
                  className=" "
                  style={{ color: "White", marginRight: "4px" }}
                />
                06 42 33 77 40
              </a>
              <br />
              <a
                href="mailto:pierre.vequaud@practigreen.fr?subject=Contact PractiGREEN"
                style={{ textDecoration: "none" }}
              >
                <RiMailOpenFill
                  className=" "
                  style={{ color: "White", marginRight: "4px" }}
                />
                pierre.vequaud@practigreen.fr
              </a>
            </p>
          </div>
          <div style={{ width: "150px" }}></div>
          <div className="center contactez column second glasmorphism">
            <img
              src={Guillaume}
              alt="Photo de Guillaume Golding"
              style={{ height: "150px", width: "auto" }}
            />
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              Guillaume Golding, Directeur Général de PractiGREEN
              <br /> Ingénieur hydrogéologue​
              <br /> Expert scientifique dans les domaines du sol et de l’eau​ ​
              <br /> <br />
              <a href="tel:+33695479687" style={{ textDecoration: "none" }}>
                <BsFillTelephoneFill
                  className=" "
                  style={{ color: "White", marginRight: "4px" }}
                />
                06 95 47 96 87
              </a>
              <br />
              <a
                href="mailto:guillaume.golding@practigreen.fr?subject=Contact PractiGREEN"
                style={{ textDecoration: "none" }}
              >
                <RiMailOpenFill
                  className=" "
                  style={{ color: "White", marginRight: "4px" }}
                />
                guillaume.golding@practigreen.fr
              </a>
            </p>
          </div>
        </div>

        <div className="desktop">
          <div style={{ textAlign: "center", height: "50em" }}>
            <iframe
              src="//v.calameo.com/?bkcode=0068867227a32dc0d0fae&mode=mini&view=book&clickto=view&clicktarget=_self&authid=3HMPCdlAJCmE"
              width="960"
              height="600"
              frameBorder="0"
              scrolling="no"
              allowFullScreen
              style={{ margin: "auto", height: "100%" }}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
