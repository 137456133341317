import React from "react";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import "../styles/style.css";
import Header from "./Header";
import Footer from "./Footer";
import Accueil from "./Accueil/Accueil.js";

function App() {
  useEffect(() => {
    if (window.location.hash === "") window.location.href = "#/Accueil";
  }, []);

  return (
    <React.Fragment>
      <div className="blanck-header"></div>
      <Header />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}

export default App;
