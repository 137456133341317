import React, { useEffect, useState, useCallback } from "react";
import { banner } from "../../datas/banner.js";
import { HashLink as Link } from "react-router-hash-link";


function Banner() {
  const [selected, setSelected] = useState(1);
  const [change, setChange] = useState(1);

  const Change = useCallback(() => {
    setChange(Math.random());
  }, []);

  useEffect(() => {
    selected === 4 ? setSelected(1) : setSelected(selected + 1);
    const interval = setInterval(() => {
      Change();
    }, 5000);
    return () => clearInterval(interval);
  }, [change]);

  return (
    <div>
      {banner.map((object) =>
        selected === object.key ? (
          <Link
          to={object.url}
          key={object.key}
        >
          <div
            className="banner "
            id={"b" + object.key}
            key={object.key}
          >
            <div className='hexagon'>
            <div className="glasmorphism text">
              <p>{object.text}</p>
              <h1>{object.sousTitre}</h1>
            </div>

            </div>
          </div>
          </Link>
        ) : null
      )}
    </div>
  );
}

export default Banner;
