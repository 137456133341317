import "./../styles/style.css";
import React, { useEffect } from "react";

function Mentions() {
  return (
    <>
      <div className="title center ">
        <p>NOS MENTIONS LÉGALES</p>
      </div>
      <div className="article center column">
        <p>
          Mentions légales en vigueur au 02/09/2021 <br />
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          Utilisateurs du site practigreen.fr les présentes mentions légales. La
          connexion et la navigation sur le site practigreen.fr par
          l’Utilisateur implique acceptation intégrale et sans réserve des
          présentes mentions légales. Ces dernières sont accessibles sur le site
          à la rubrique « Mentions légales ».
          <br />
          <br /> ARTICLE 1 : L’éditeur <br />
          L'édition du site practigreen.fr est assurée par la Société SAS
          PractiGreen au capital de 2000 euros, immatriculée au RCS de Bordeaux
          sous le numéro 902027291 dont le siège social est situé au 10 rue
          Victor Hugo, 33400 Talence, numéro de téléphone 06.42.33.77.40 /
          06.95.47.96.87, adresse e-mail : contact@practigreen.fr. N° de TVA
          intracommunautaire : FR38902027291. Le site a été développé par Jeanne
          Barrière. Web-design : Pierre Véquaud. Directeur de la communication :
          Guillaume Golding
          <br />
          <br />
          ARTICLE 2 : L’hébergeur
          <br /> L'hébergeur du site practigreen.fr est la Société OVH, dont le
          siège social est situé au 2 rue Kellermann - 59100 Roubaix - France ,
          avec le numéro de téléphone : 1007.
          <br />
          <br /> ARTICLE 3 : Accès au site
          <br /> Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas
          de force majeure, interruption programmée ou non et pouvant découler
          d’une nécessité de maintenance. En cas de modification, interruption
          ou suspension des services le site practigreen.fr ne saurait être tenu
          responsable.
          <br />
          <br /> ARTICLE 4 : Collecte des données
          <br /> Le site est exempté de déclaration à la Commission Nationale
          Informatique et Libertés (CNIL) dans la mesure où il ne collecte
          aucune donnée concernant les utilisateurs.
          <br />
          <br /> ARTICLE 5 : Cookies <br />
          L’Utilisateur est informé que lors de ses visites sur le site, un
          cookie peut s’installer automatiquement sur son logiciel de
          navigation. En naviguant sur le site, il les accepte. Un cookie est un
          élément qui ne permet pas d’identifier l’Utilisateur mais sert à
          enregistrer des informations relatives à la navigation de celui-ci sur
          le site Internet. L’Utilisateur pourra désactiver ce cookie par
          l’intermédiaire des paramètres figurant au sein de son logiciel de
          navigation.
          <br />
          <br /> ARTICLE 6 : Propriété intellectuelle
          <br /> Toute utilisation, reproduction, diffusion, commercialisation,
          modification de toute ou partie du site practigreen.fr, sans
          autorisation de l’Editeur est prohibée et pourra entraînée des actions
          et poursuites judiciaires telles que notamment prévues par le Code de
          la propriété intellectuelle et le Code civil.
          <br />
          <br /> ARTICLE 7 : Crédits photos
          <br /> Photo by Fré Sonneveld on Unsplash <br />
          Photo by Benjamin Combs on Unsplash <br />
          Photo by CHUTTERSNAP on Unsplash
          <br /> Photo by Sean Pollock on Unsplash
          <br /> Photo by Tyler Franta on Unsplash
          <br /> Photo by Scott Blake on Unsplash
          <br /> Photo by Mads Eneqvist on Unsplash{" "}
        </p>
      </div>
    </>
  );
}

export default Mentions;
