import "../../styles/style.css";
import React, { useState, useEffect } from "react";
import ISO from "../../assets/ISO.png";
import demarches from "../../assets/demarche.png";
import { demarche } from "../../datas/demarche.js";

function NotreMission() {
  const [selected, setSelected] = useState("");

  return (
    <>
      <div className="title center" id="besoins">
        <p>VOS BESOINS & ENJEUX</p>
      </div>
      <div className="article">
        <p className="center description">
          Vous vous posez de nombreuses questions… et c’est normal. Alors même
          que vous êtes conscients de l’urgence environnementale, une transition
          écologique peut sembler complexe et chronophage.
        </p>
        <div className="honeycomb center">
          <div className="hex blue">
            <p>
              Quelles sont mes obligations légales en matière d’environnement ?
            </p>
          </div>
          <div className="hex blue">
            <p>
              Comment communiquer et montrer mon engagement social et
              environnemental ?
            </p>
          </div>

          <div className="hex blue">
            <p>
              Comment améliorer le confort de vie de mes collaborateurs au
              travail ?
            </p>
          </div>
          <div className="hex blue">
            <p>
              Quels avantages pourrais-je tirer d’une démarche de transition
              environnementale ?
            </p>
          </div>
        </div>
        <div className="honeycomb center">
          <div className="hex blue">
            <p>Comment construire une chaîne de valeur éco-responsable ?</p>
          </div>

          <div className="hex blue">
            <p>
              Quelles solutions concrètes pour prendre en compte l’environnement
              dans mon activité ?
            </p>
          </div>
          <div className="hex blue">
            <p>
              Quel est l’impact environnemental et climatique de mon entreprise ?
            </p>
          </div>

          <div className="hex blue">
            <p>
              Comment réduire l’impact environnemental et climatique de mon
              entreprise ?
            </p>
          </div>
        </div>
        <p className=" description">
          Face à l’urgence climatique, il est indispensable de prendre des
          mesures fortes et impactantes afin de préserver l’environnement. <em>PractiGREEN</em> vous accompagne afin de répondre à vos interrogations et définir des
          solutions environnementales adaptées à votre organisation.
        </p>
        <div className="glasmorphism second marge column missions">
          <h1 className="center">Votre environnement, pour l'environnement</h1>
          <div className="center">
            <img src={ISO} alt="ISO_26000" />
            <p>
              <h2 style={{ fontSize: "1em" }}>Impliquer & fédérer vos parties prenantes </h2>
              Votre entreprise fait partie d’un écosystème complexe, et il est
              nécessaire que vos démarches de transition écologique puissent
              intéresser, concerner et impliquer l’ensemble de vos parties
              prenantes.
              <br />
              <br />
              <h2 style={{ fontSize: "1em" }}>Avec une démarche durable & respectueuse </h2>
              La mise en place de démarches de transition écologique, tenant
              compte de vos partie prenantes, vous permettra de développer une
              stratégie de développement durable & soutenable dans le temps,
              dans les respect de vos collaborateurs, clients et partenaires.
              <br />
              <br />
              <h2 style={{ fontSize: "1em" }}>En accord avec vos besoins & obligations  </h2>
              Ainsi, afin de pouvoir répondre à vos interrogations & besoins,
              nous appuyons notre démarche sur des outils & réglementations
              comme par exemple la norme ISO 26000. Ceci nous permet une
              approche holistique de la transition écologique, et de respecter
              l’ensemble de vos parties prenantes.
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>

      <div className="title center" id="demarche">
        <p id="demarche">NOTRE DÉMARCHE</p>
      </div>
      <div className="article">
        <div className="desktop">
          <div className="center demarche" style={{marginBottom:'20px'}}>
            {demarche.map((object) => (
              <div
                key={object.key}
                className={"phase" + object.key + " item center column"}
                onMouseEnter={() => setSelected(object.key)}
                onMouseLeave={() => setSelected("")}
              >
                {selected === object.key ? (
                  <>
                    <h2>PHASE {object.key}</h2>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/demarche/fleche" +
                        object.key +
                        ".png"
                      }
                      alt={object.title}
                    />
                    <div className="line"></div>
                    <p className="demarche-text">{object.text}</p>
                  </>
                ) : (
                  <>
                    <h2>PHASE {object.key}</h2>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/demarche/fleche" +
                        object.key +
                        ".png"
                      }
                      alt={object.title}
                    />
                    <div className="line"></div>
                    <div className="img center">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/demarche/" +
                          object.icon
                        }
                        alt={object.title}
                      />
                    </div>
                    <div className="line" style={{ height: "30px" }}></div>
                    <h2 className="demarche-title">{object.title}</h2>
                    <p>{object.description}</p>
                  </>
                )}
              </div>
            ))}
          </div>
          
        </div>
        <div className="phone">
          <div className="center demarche">
            {demarche.map((object) => (
              <div
                key={object.key}
                className={"phase" + object.key + " item center column"}
                onClick={() => selected === object.key ? setSelected("") : setSelected(object.key)}
                onMouseLeave={() => setSelected("")}
              >
                {selected === object.key ? (
                  <>
                    <h2>PHASE {object.key}</h2>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/demarche/fleche" +
                        object.key +
                        ".png"
                      }
                      alt={object.title}
                    />
                    <div className="line"></div>
                    <p className="demarche-text">{object.text}</p>
                  </>
                ) : (
                  <>
                    <h2>PHASE {object.key}</h2>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/demarche/fleche" +
                        object.key +
                        ".png"
                      }
                      alt={object.title}
                    />
                    <div className="line"></div>
                    <div className="img center">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/demarche/" +
                          object.icon
                        }
                        alt={object.title}
                      />
                    </div>
                    <div className="line" style={{ height: "30px" }}></div>
                    <h2 className="demarche-title">{object.title}</h2>
                    <p>{object.description}</p>
                  </>
                )}
              </div>
            ))}
          </div>
          
        </div>
      </div>

      <div className="title center" id="clients">
        <p id="demarche">NOS CLIENTS</p>
      </div>
      <div className="article">

      <div className='clients center'>
        <div id="build" className="center">
          <p>Grandes Entreprises & Grandes Industries</p>
        </div>
        <div id="business" className="center">
          <p>Entreprises de taille intermédiaire & PME/PMI</p>
        </div>
        <div id="public" className="center">
          <p>Organismes publics & Collectivités</p>
        </div>
      </div>

      </div>
    </>
  );
}

export default NotreMission;
