import logo from "../assets/Logo_Long.png";
import "../styles/style.css";
import { menu } from "../datas/menu.js";
import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

function Header() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="Header">
        <Link
          to="/Accueil"
          onClick={() => window.scrollTo(0, 0)}
          style={{ height: "100%" }}
        >
          <img
            src={logo}
            alt="practigreen_logo"
            onClick={() => {
              window.scrollTo(0, 0);
              document.title = "PractiGREEN - Accueil";
            }}
            style={{ cursor: "pointer" }}
          />
        </Link>
        <ul className="menu">
          {menu.map((object) => (
            <li
              key={object.key}
              onClick={() => {
                document.title = "PractiGREEN - " + object.title;
              }}
            >
              <Link
                to={object.url}
                style={{ height: "100%" }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <p id={object.key === "blog" ? "blog-hover" : null}>
                  {object.title}
                </p>{" "}
              </Link>
              {object.item.length > 0 ? (
                <ul>
                  {object.item.map((item) => (
                    <Link
                      to={object.url + "#" + item.key}
                      style={{ height: "100%" }}
                      key={item.key}
                    >
                      <li>
                        <p>{item.title}</p>
                      </li>
                    </Link>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </div>

      <div
        id="menuToggle"
        className={open ? "open" : null}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul className="menu-phone">
          {menu.map((object) => (
            <li
              onClick={() => {
                document.title = "PractiGREEN - " + object.title;
              }}
              key={object.key}
            >
              <Link
                to={object.url}
                style={{ height: "100%" }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <p id={object.key === "blog" ? "blog-hover" : null}>{object.title}</p>
              </Link>
              {object.item.length > 0 ? (
                <ul>
                  {object.item.map((item) => {
                    return (
                      <Link
                        to={object.url + "#" + item.key}
                        style={{ height: "100%" }}
                        key={item.key}
                      >
                        <li>
                          <p>{item.title}</p>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Header;
