import "../../styles/style.css";
import React, { useEffect } from "react";
import { solutions } from "../../datas/solutions";
import { useState } from "react";
import { BiDownArrow } from "react-icons/bi";

function Solutions() {
  const [selected, setSelected] = useState("");
  const [itemSelected, setItem] = useState("");
  const [cache, setcache] = useState(true);

  useEffect(() => {
    setItem("");
    setcache(true);
    // window.location.href = "#" + selected;
    // window.scrollTo(0,(window.scrollY-70));
  }, [selected]);

  useEffect(() => {
    if (itemSelected !== "") {
      // window.location.href = "#" + itemSelected;
      // window.scrollTo(0,(window.scrollY-70));
    }
  }, [itemSelected]);

  return (
    <div>
      <div className="title center ">
        <p>NOS SOLUTIONS</p>
      </div>
      <div className="article center column">
        <div className="desktop">
          <h2 style={{ textAlign: "center", fontWeight: "300" }}>
            {" "}
            Découvrez nos solutions en cliquant sur les thèmes
          </h2>
          <div className="solutions">
            <ul>
              {solutions.map((object) => (
                <li
                  key={object.key}
                  onClick={() => {
                    selected === object.key
                      ? setSelected("")
                      : setSelected(object.key);
                  }}
                >
                  <div className="solutions-title">
                    <div id={object.image}></div>
                    <h3
                      className={
                        selected !== object.key && selected !== ""
                          ? "center grey"
                          : "center"
                      }
                    >
                      {object.title}
                    </h3>
                  </div>
                  {selected === object.key ? (
                    <BiDownArrow
                      className="react-icons "
                      style={{ textAlign: "center" }}
                    />
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {selected === "" ? (
              <div className="center column marge border-bottom">
                <img
                  src={process.env.PUBLIC_URL + "/img/solutions/intro.png"}
                  alt="innovation"
                  style={{ height: "100px", width: "100px" }}
                />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.2em",
                  }}
                >
                  Face à l’urgence climatique, il est nécessaire de définir des
                  solutions environnementales positives, impactantes et
                  innovantes.
                  <br />
                  Pour cela, <em>PractiGREEN</em> met à votre service son
                  expertise scientifique, afin de <em>DÉFINIR</em> des solutions
                  qui vous conviennent, à la pointe des dernières avancées en
                  matières de recherches environnementales.
                  <br />
                  Nous surveillons les dernières avancées scientifiques à
                  travers une bibliographie complète des grandes revues
                  scientifiques.
                  <br />
                  <br />
                  Nos experts peuvent ainsi <em>ÉVALUER & OPTIMISER</em> vos
                  pratiques. Nous pouvons aussi vous proposer des nouvelles
                  solutions, à même de répondre à vos besoins et ainsi montrer à
                  vos parties prenantes votre engagement environnemental.
                  <br />
                  <br />
                </p>
              </div>
            ) : (
              <div
                className=" column border-bottom box-animation solutions-item"
                onClick={() => {
                  setSelected("");
                }}
              >
                {solutions.map((object) =>
                  object.key === selected
                    ? object.item.map((item) => (
                        <div
                          key={item.key}
                          className={item.border ? "border" : null}
                        >
                          <h2>{item.title}</h2>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/solutions/" +
                              item.icon
                            }
                            alt={item.descrition}
                            style={{
                              height: "100px",
                              width: "100px",
                              alignSelf: "center",
                            }}
                          />

                          {item.descrition}
                        </div>
                      ))
                    : null
                )}
              </div>
            )}
          </div>
        </div>
        <div className="phone">
          <div
            className="center column marge border-bottom"
            onClick={() => {
              setSelected("");
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/solutions/intro.png"}
              alt="innovation"
              style={{ height: "100px", width: "100px" }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "1em",
              }}
            >
              Face à l’urgence climatique, il est nécessaire de définir des
              solutions environnementales positives, impactantes et innovantes.
              <br />
              Pour cela, <em>PractiGREEN</em> met à votre service son expertise
              scientifique, afin d’<em>EVALUER-OPTIMISER-DEFINIR</em> des
              solutions qui vous conviennent, à la pointe des dernières avancées
              en matière de recherches environnementales.
            </p>
          </div>
          <div className="solutions">
            <ul>
              {solutions.map((object) => (
                <li
                  key={object.key}
                  onClick={() => {
                    setSelected(object.key);
                  }}
                >
                  <div
                    id={object.key}
                    className="solutions-title"
                    onClick={() => {
                      selected === object.key
                        ? setcache(!cache)
                        : setcache(!cache);
                      // eslint-disable-next-line no-unused-expressions
                    }}
                  >
                    <div id={object.image}></div>
                    <h3
                      className={
                        selected !== object.key && selected !== "" && cache
                          ? "center grey"
                          : "center"
                      }
                    >
                      {object.title}
                    </h3>
                  </div>
                  {selected === object.key && cache ? (
                    <>
                      <BiDownArrow
                        className="react-icons "
                        style={{ textAlign: "center" }}
                      />
                      <div className=" column border-bottom box-animation solutions-item">
                        {object.item.map((item) => (
                          <div key={item.key} id={item.key}>
                            <div
                              onClick={() => {
                                itemSelected === item.key
                                  ? setItem("")
                                  : setItem(item.key);
                              }}
                            >
                              <h2>{item.title}</h2>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/solutions/" +
                                  item.icon
                                }
                                alt={item.descrition}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  alignSelf: "center",
                                }}
                              />
                            </div>

                            <p
                              style={{ whiteSpace: "pre-line" }}
                              hidden={item.key !== itemSelected}
                            >
                              {item.descrition}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
