import React from "react";
import "./index.css";
import App from "./components/App";
import { render } from "react-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import Accueil from "./components/Accueil/Accueil.js";
import NotreMission from "./components/NotreMission/NotreMission.js";
import Solutions from "./components/Solutions/Solutions.js";
import Contact from "./components/Contact/Contact.js";
import Qui from "./components/Qui/Qui.js";
import Blog from "./components/Blog/Blog.js";
import Article from "./components/Blog/Article";
import Mentions from "./components/Mentions";

const rootElement = document.getElementById("root");
render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App key={window.location.pathname} />}>
        <Route path="Accueil" element={<Accueil />} />
        <Route path="NotreMission" element={<NotreMission />} />
        <Route path="Solutions" element={<Solutions />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="QuiSommeNous" element={<Qui />} />
        <Route path='Mentions' element={<Mentions />} />
        <Route path="Blog" element={<Blog />} />
        <Route path="Blog/:articleKey.html" element={<Article />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Route>
    </Routes>
  </HashRouter>,
  rootElement
);
