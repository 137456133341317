export const menu = [
  {
    title: "Accueil",
    key: "accueil",
    url: "/Accueil",
    item: [],
  },
  {
    title: "Notre mission",
    key: "mission",
    url: "/NotreMission",
    item: [
      {
        title: "Vos besoins & enjeux",
        key: "besoins",
      },
      {
        title: "Notre démarche",
        key: "demarche",
      },
      {
        title: "Nos clients",
        key: "clients",
      },
    ],
  },
  {
    title: "Nos solutions",
    key: "solutions",
    url: "/Solutions",
    item: [],
  },
  {
    title: "Qui sommes nous ?",
    key: "qui",
    url: "/QuiSommeNous",
    item: [
      {
        title: "Fondateurs",
        key: "equipe",
      },
      {
        title: "Notre vision",
        key: "vision",
      },
      {
        title: "Nos engagements",
        key: "engagements",
      },
      {
        title: "Nos partenaires",
        key: "partenaires",
      },
    ],
  },
  {
    title: "Blog",
    key: "blog",
    url: "/Blog",
    item: [],
  },
  {
    title: "Contactez-nous",
    key: "contact",
    url: "/Contact",
    item: [],
  },
];
