import "../../styles/style.css";
import React, { useState } from "react";
import Banner from "./Banner";
import { banner } from "../../datas/banner.js";
import innov from "../../assets/valeurs/innov.png";
import pratique from "../../assets/valeurs/pratique.png";
import SM from "../../assets/valeurs/SM.png";
import { solutions } from "../../datas/solutions";
import { demarche } from "../../datas/demarche.js";
import { HashLink as Link } from "react-router-hash-link";

function Acceuil() {
  const [selected, setSelected] = useState("");

  return (
    <>
      <Banner />
      <div className="article center column ">
        <div className="first">
          <div>
            <h1 className="left center">
              <strong> 80% </strong>
            </h1>
            <h1 className="left center left-phone">
              {" "}
              des Français sont conscients des enjeux climatiques{" "}
            </h1>
          </div>
          <h2 className="center">
            {" "}
            Quand allez-vous vous saisir de ces enjeux ?
          </h2>
        </div>
      </div>
      <div className="title center " id="engagements">
        <p id="equipe">NOS ENGAGEMENTS</p>
      </div>
      <Link to="/QuiSommeNous" onClick={() => {window.scrollTo(0, 0)}}>
        <div className="article center column ">
          <div className="honeycomb center engagement">
            <div className="hex green-hex center">
              <p className="center column">
                <em>PRATIQUE</em>
                <img
                  src={pratique}
                  alt="icon pratique"
                  style={{ height: "60px", width: "auto", marginTop: "5px" }}
                />
                Nous nous engageons à vous accompagner et vous guider dans vos
                démarches​
              </p>
            </div>
            <div className="line-h"></div>
            <div className="hex green-hex">
              <p className="center column">
                <em>INNOVANT</em>
                <img
                  src={innov}
                  alt="icon innovant"
                  style={{ height: "60px", width: "auto", marginTop: "5px" }}
                />
                Nos experts surveillent les dernières avancées scientifiques et
                technologiques
              </p>
            </div>
            <div className="line-h"></div>
            <div className="hex green-hex">
              <p className="center column">
                <em>SUR MESURE</em>
                <img
                  src={SM}
                  alt="icon sur mesure"
                  style={{ height: "60px", width: "auto", marginTop: "5px" }}
                />
                Nous nous engageons à vous conseiller des solutions adaptées à
                vos besoins​{" "}
              </p>
            </div>
          </div>
          <h2>Plus d'informations : Qui sommes nous ?</h2>
        </div>
      </Link>

      <div className="title center ">
        <p>NOS SOLUTIONS</p>
      </div>
      <div className="article center column">
        <Link to="/Solutions" onClick={() => window.scrollTo(0, 0)}>
          <div className="solutions" style={{ padding: "20px" }}>
            <ul style={{ justifyContent: "space-around" }}>
              {solutions.map((object) => (
                <li key={object.key}>
                  <div className="solutions-title">
                    <div id={object.image}></div>
                    <h3 className={"center"}>{object.title}</h3>
                  </div>
                </li>
              ))}
            </ul>
            <h2 style={{ textAlign: "center" }}>
              Plus d'informations : Nos solutions
            </h2>
          </div>
        </Link>
      </div>

      <div className="title center" id="demarche">
        <p id="demarche">NOTRE DÉMARCHE</p>
      </div>
      <div className="article">
        <Link to="/NotreMission" onClick={() => window.scrollTo(0, 0)} style={{textAlign:'center'}}>
          <div className="desktop">
            <div className="center demarche" style={{ marginBottom: "20px" }}>
              {demarche.map((object) => (
                <div
                  key={object.key}
                  className={"phase" + object.key + " item center column"}
                  onMouseEnter={() => setSelected(object.key)}
                  onMouseLeave={() => setSelected("")}
                >
                  {selected === object.key ? (
                    <>
                      <h2>PHASE {object.key}</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/demarche/fleche" +
                          object.key +
                          ".png"
                        }
                        alt={object.title}
                      />
                      <div className="line"></div>
                      <p className="demarche-text">{object.text}</p>
                    </>
                  ) : (
                    <>
                      <h2>PHASE {object.key}</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/demarche/fleche" +
                          object.key +
                          ".png"
                        }
                        alt={object.title}
                      />
                      <div className="line"></div>
                      <div className="img center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/demarche/" +
                            object.icon
                          }
                          alt={object.title}
                        />
                      </div>
                      <div className="line" style={{ height: "30px" }}></div>
                      <h2 className="demarche-title">{object.title}</h2>
                      <p>{object.description}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="phone">
            <div className="center demarche">
              {demarche.map((object) => (
                <div
                  key={object.key}
                  className={"phase" + object.key + " item center column"}
                  onClick={() =>
                    selected === object.key
                      ? setSelected("")
                      : setSelected(object.key)
                  }
                  onMouseLeave={() => setSelected("")}
                >
                  {selected === object.key ? (
                    <>
                      <h2>PHASE {object.key}</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/demarche/fleche" +
                          object.key +
                          ".png"
                        }
                        alt={object.title}
                      />
                      <div className="line"></div>
                      <p className="demarche-text">{object.text}</p>
                    </>
                  ) : (
                    <>
                      <h2>PHASE {object.key}</h2>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/demarche/fleche" +
                          object.key +
                          ".png"
                        }
                        alt={object.title}
                      />
                      <div className="line"></div>
                      <div className="img center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/demarche/" +
                            object.icon
                          }
                          alt={object.title}
                        />
                      </div>
                      <div className="line" style={{ height: "30px" }}></div>
                      <h2 className="demarche-title">{object.title}</h2>
                      <p>{object.description}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          <h2 >Plus d'informations : Notre mission</h2>
        </Link>
      </div>
    </>
  );
}

export default Acceuil;
