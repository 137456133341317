import "../../styles/style.css";
import React, { useEffect } from "react";
import { getArticle } from "../../datas/blog";
import { ImLinkedin, ImFacebook2 } from "react-icons/im";
import { useParams } from "react-router-dom";

function Article() {
  let params = useParams();
  let article = getArticle(params.articleKey);

  useEffect(() => {
    var meta = document.createElement("meta");
    meta.property = "og:url";
    meta.content = "https://practigreen.fr/#/Blog/Defis_environnementaux.html";
    document
      .getElementsByTagName("head")[0]
      .append(
        <meta
          property="og:url"
          content="https://practigreen.fr/#/Blog/Defis_environnementaux.html"
        />
      );
  }, []);

  function shareLinkedin(e) {
    e.preventDefault();
    fetch("https://api.linkedin.com/v2/ugcPosts", {
      method: "POST",
      body: JSON.stringify({
        author: "urn:li:person:8675309",
        lifecycleState: "PUBLISHED",
        specificContent: {
          "com.linkedin.ugc.ShareContent": {
            shareCommentary: {
              text: "j'ai lu cet article sur PractiGREEN !",
            },
            shareMediaCategory: "ARTICLE",
            media: [
              {
                status: "READY",
                description: {
                  text: article.resume,
                },
                originalUrl:
                  "https://www.practigreen.fr/#/Blog/Defis_environnementaux",
                title: {
                  text: article.title,
                },
              },
            ],
          },
        },
        visibility: {
          "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC",
        },
      }),
    });
  }

  return (
    <>
      {/* <div className="float-button">
        <div className="glasmorphism social center">
          <a
            href="https://www.facebook.com/sharer/sharer.php?http://www.practigreen.fr/#/Blog/Defis_environnementaux.html"
            target="_blank"
          >
            <ImFacebook2
              className=" "
              style={{
                textAlign: "center",
                color: "White",
                fontSize: "2em",
                margin: 0,
              }}
            />
          </a>
        </div>
        <div
          className="glasmorphism social center"
          onClick={(e) => shareLinkedin(e)}
        >
          <ImLinkedin
            className=""
            style={{ textAlign: "center", color: "White", fontSize: "2em" }}
          />
        </div>
      </div> */}

      <div className="banner-article">
        {article.banner} <h2>{article.title}</h2>
      </div>
      <div className="article">
        <div className="main-article">{article.text}</div>
      </div>
    </>
  );
}

export default Article;
