import "../styles/style.css";
import { ImLinkedin, ImFacebook2 } from "react-icons/im";
import { RiMailOpenFill } from "react-icons/ri";
import plaquette from '../assets/Plaquette_PractiGREEN.pdf'
import { HashLink as Link } from "react-router-hash-link";


function Footer() {
  return (
    <div className="Footer">
      <div className="contact center">
        <p className="glasmorphism">
          <a
            href="mailto:contact@practigreen.fr?subject=Contact PractiGREEN"
            style={{ color: "white", textDecoration: "none" }}
          >
            <RiMailOpenFill
              className=" "
              style={{
                color: "White",
                textAlign: "center",
                marginRight: "4px",
              }}
            />
            contact@practigreen.fr
          </a>{" "}
          <br />
          <Link
                to="Contact"
                style={{ height: "100%" }}
                onClick={() => window.scrollTo(0, 0)}
              >
          <span
            style={{ color:'white' }}
          >
            Contactez-nous
          </span>
          </Link>
        </p>
      </div>
      <div className="center column" style={{ margin: "10px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <a href="https://www.facebook.com/PractiGreen">
            <div className="glasmorphism social center">
              <ImFacebook2
                className=" "
                style={{ textAlign: "center", color: "White", fontSize: "2em" }}
              />
            </div>
          </a>
          <a href="https://www.linkedin.com/company/practigreen">
            <div className="glasmorphism social center">
              <ImLinkedin
                className=""
                style={{ textAlign: "center", color: "White", fontSize: "2em" }}
              />
            </div>
          </a>
        </div>
        <p style={{ margin: "15px" }}><a href={plaquette} download style={{color:"white"}}>Télécharger notre plaquette</a></p>
      </div>
      <div className="center column">
        <p>© PractiGREEN - 2021</p>
        <Link
                to="Mentions"
                style={{ height: "100%" }}
                onClick={() => window.scrollTo(0, 0)}
              >
        <p style={{ color:"white" }}>Mentions légales</p>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
