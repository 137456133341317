export const demarche = [
  {
    title: "Audit &\n expertise",
    key: "1",
    description:
      "Caractérisation de\n vos enjeux & besoins \nDiagnostic \nÉvaluation préalable",
    text: "PractiGREEN définit vos besoins en termes de démarches environnementales et sociétales, et vous accompagne dans l’évaluation de vos pratiques, de vos impacts sociétaux et de vos impacts environnementaux. ",
    icon: "phase1.png",
  },
  {
    title: "Proposition de\n solutions",
    key: "2",
    description:
      "Benchmark \nAnalyse – R&D \nSolutions concrètes et\n sur mesure",
    text: "PractiGREEN définit des solutions adaptées aux enjeux de votre organisation et à vos activités. Ces solutions sont étudiées et évaluées selon différents critères (économiques, environnementaux et sociétaux) afin de répondre à vos besoins.\n\n Les solutions proposées s’inscrivent dans un contexte concurrentiel et sont à la pointe des dernières avancées en matière de recherche environnementale.",

    icon: "phase2.png",
  },
  {
    title: "Accompagnement\n dans la mise en place",
    key: "3",
    description:
      "Plan d’action \nConcrétisation \nContact des prestataires \nCoaching \nCommunication",
    text: "PractiGREEN vous accompagne dans la définition et le pilotage d’un plan d’action viable et adapté à votre activité.\n\n Ce plan d’action permet le déploiement de vos solutions environnementales, en accord avec votre stratégie RSE et vos besoins.\n\n PractiGREEN reste à vos côtés pour effectuer les démarches nécessaires à l’optimisation de votre chaîne de valeurs, et communiquer auprès de vos parties prenantes. ",
    icon: "phase3.png",
  },
  {
    title: "Retour\n d’expérience",
    key: "4",
    description:
      "Ajustement \nIndicateurs de réussite\n sur mesure \nÉvaluation finale",
    text: "PractiGREEN évalue de façon ponctuelle ou récurrente, vos pratiques environnementales et les solutions mises en place.\n\n Cette évaluation nous permet de dresser des recommandations à même d’optimiser vos pratiques.\n\n Pour cela PractiGREEN propose des indicateurs environnementaux, sociaux et de gouvernance, afin d’analyser la performance et l’implémentation des solutions environnementales, au cœur de votre stratégie opérationnelle et RSE.  ",

    icon: "phase4.png",
  },
];
