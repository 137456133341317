import "../../styles/style.css";
import React from "react";
import Guillaume from "../../assets/guillaumenb.png";
import Pierre from "../../assets/pierrenb.png";
import durable from "../../assets/durable.png";
import innov from "../../assets/valeurs/innov.png";
import pratique from "../../assets/valeurs/pratique.png";
import SM from "../../assets/valeurs/SM.png";
import { partenaires } from "../../datas/partenaires.js";

function Qui() {
  return (
    <>
      <div className="title center ">
        <p id="equipe">FONDATEURS</p>
      </div>
      <div className="article center column">
        <div>
          <p style={{ fontSize: "1.2em", textAlign: "center" }}>
            En 2021, nous décidions de créer <em>PractiGREEN</em>, société
            d’ingénierie environnementale. Cette idée fait écho à une prise de
            conscience globale des entreprises et des collectivités, soucieuses
            de mettre en place une transition écologique, en réponse aux
            changements environnementaux. ​
            <br />
            <br />
            <em>PractiGREEN</em> est créatrice de solutions de{" "}
            <em>transition écologique adaptées et sur mesure</em> pour les
            entreprises et les collectivités.​
            <br />
            <em>PractiGREEN</em>, pour ses clients,{" "}
            <em>
              conduit des audits, propose des conseils experts et un
              accompagnement personnalisé.
            </em>{" "}
          </p>
        </div>
        <div className=" nous" style={{ marginBottom: "40px" }}>
          <div className="center column second glasmorphism">
            <div className="center" style={{ width: "100%" }}>
              <p style={{ fontWeight: "bold", textAlign:'left' }}>
                Pierre Véquaud, Président de PractiGREEN <br /> Docteur en
                Sciences Environnementales​ <br /> Expert des enjeux
                environnementaux et climatiques​
              </p>
              <img
                src={Pierre}
                alt="Photo de Pierre Véquaud"
                style={{ height: "150px", width: "auto" }}
              />
            </div>
            <p style={{ fontSize: "1.2em" }}>
              <br /> ​Pierre s’est spécialisé dans l’étude des climats actuels
              et passés, et l’évolution des environnements par le développement
              d’outils techniques et statistiques. Ces outils permettent une
              meilleur compréhension des contraintes environnementales et des
              enjeux climatiques.​ ​ <br /> <br /> Concerné par les enjeux​ liés
              aux changements environnementaux, Pierre​ souhaite mettre au
              service de la société son expérience et ses connaissances et​
              apporter des solutions concrètes aux problématiques
              environnementales.​
            </p>
          </div>
          <div style={{ width: "150px" }}></div>
          <div className="center column second glasmorphism">
            <div className="center" style={{ width: "100%" }}>
              <img
                src={Guillaume}
                alt="Photo de Guillaume Golding"
                style={{ height: "150px", width: "auto" }}
              />
              <p style={{ fontWeight: "bold", textAlign:'right'  }}>
                Guillaume Golding, Directeur Général de PractiGREEN
                <br /> Ingénieur hydrogéologue​
                <br /> Expert scientifique dans les domaines du sol et de l’eau​
                ​
              </p>
            </div>
            <p style={{ fontSize: "1.2em" }}>
              <br /> Fort d’une expérience de plusieurs années dans la
              recherche, Guillaume s’est spécialisé dans les questions du
              carbone dans le sol et ses transferts vers les milieux aquatiques
              et vers l’atmosphère ainsi que l’impact des organismes vivants sur
              le cycle du carbone. Son approche se veut pluridisciplinaire.
              <br /> <br /> Guillaume souhaite mettre ses connaissances de
              l'environnement au service des acteurs privés et publics pour les
              aider à se saisir des questions autour des enjeux environnementaux
              et à préparer leur transition écologique en douceur.​​
            </p>
          </div>
        </div>
        <div>
          <p style={{ fontSize: "1.2em", textAlign: "center" }}>
            À l’origine de <em>PractiGREEN</em>, nous sommes deux amis,{" "}
            <em>Pierre Véquaud & Guillaume Golding</em>. <br /> Nous sommes
            façonnés et définis par nos cursus et nos origines. Nos{" "}
            <em>convictions environnementales et sociales</em> nous conduisent à
            agir et à être au plus près des entreprises et des collectivités
            pour leur proposer des{" "}
            <em>solutions environnementales concrètes</em> qui répondent à leurs
            attentes.​ Notre équipe repose sur la{" "}
            <em>complémentarité de nos profils scientifiques</em>, et nous
            mettons <em>nos expertises</em> à votre service pour vous{" "}
            <em>
              conseiller, mener à bien votre transition écologique et vous
              accompagner
            </em>{" "}
            tout au long de votre projet. ​ ​
          </p>
        </div>
      </div>
      <div className="title center " id="vision">
        <p id="equipe">NOTRE VISION</p>
      </div>
      <div className="article center column ">
        <div className="center second glasmorphism marge vision">
          <div className="center column">
            <img src={durable} alt="Schéma du développement durable" />
            <p style={{}} className="legende">
              Développement durable : « un développement qui répond aux besoins
              du présent sans compromettre la capacité des générations futures à
              répondre aux leurs », citation de Mme Gro Harlem Brundtland,
              Premier Ministre norvégien (1987) . Dans un monde idéal, la
              convergence entre les enjeux économiques, sociaux et écologiques
              serait totale. Adapté du Rapport Brundtland, 1987.​{" "}
            </p>
          </div>

          <div>
            <p style={{ fontSize: "1.2em" }}>
              ​L’écologie est trop souvent perçue comme culpabilisante. Nous
              sommes convaincus qu’une transition écologique efficace devra
              nécessairement passer par une évolution des mentalités et des
              pratiques. Or, les solutions proposées apparaissent souvent
              contraignantes ou trop généralistes.
              <br />​ <br />
              ​​La technologie et les avancées de nos sociétés nous permettent
              aujourd’hui d’avoir accès à un important confort de vie, qui doit
              être préservé, en accord avec la sauvegarde environnementale. Les
              aspects sociaux et économiques sont donc indissociables de nos
              services.
            </p>

            <p
              style={{
                fontSize: "1.2em",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <br />
              ​Ainsi, avec PractiGREEN, nous portons la conviction que le
              développement écologique peut, et doit, aller de pair avec le
              développement économique et la hausse du confort de vie et de
              travail de nos clients.​ ​ <br />​ <br />​ <br />
              EFFICACITÉ - TRANSPARENCE - INGÉNIOSITÉ
              <br />« Pas de blabla, pas de Greenwashing »
            </p>
          </div>
        </div>
      </div>
      <div className="title center " id="engagements">
        <p id="equipe">NOS ENGAGEMENTS</p>
      </div>
      <div className="article center column ">
        <div>
          <p style={{ fontSize: "1.2em", textAlign: "center" }}>
            Changement climatique, perte de la biodiversité, atteintes
            environnementales… La transition écologique de notre société est
            aujourd’hui un enjeu majeur, mais les démarches apparaissent
            complexes et chronophages pour les entreprises et les
            collectivités.​ <br />​ <br />​ L’ambition ultime de{" "}
            <em>PractiGREEN</em> est de vous offrir les clés d’une transition
            écologique réussie, en vous conseillant, vous accompagnant et vous
            guidant tout au long de vos démarches.
          </p>
        </div>
        <div className="honeycomb center engagement">
          <div className="hex green-hex center">
            <p className="center column">
              <em>PRATIQUE</em>
              <img
                src={pratique}
                alt="icon pratique"
                style={{ height: "60px", width: "auto", marginTop: "5px" }}
              />
              Nous nous engageons à vous accompagner et vous guider dans vos
              démarches​
            </p>
          </div>
          <div className="line-h"></div>
          <div className="hex green-hex">
            <p className="center column">
              <em>INNOVANT</em>
              <img
                src={innov}
                alt="icon innovant"
                style={{ height: "60px", width: "auto", marginTop: "5px" }}
              />
              Nos experts surveillent les dernières avancées scientifiques et
              technologiques
            </p>
          </div>
          <div className="line-h"></div>
          <div className="hex green-hex">
            <p className="center column">
              <em>SUR MESURE</em>
              <img
                src={SM}
                alt="icon sur mesure"
                style={{ height: "60px", width: "auto", marginTop: "5px" }}
              />
              Nous nous engageons à vous conseiller des solutions adaptées à vos besoins​{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="title center " id="partenaires">
        <p id="partenaires">NOS PARTENAIRES</p>
      </div>
      <div className="article center column ">
        <div className="partenaires center">
          {partenaires.map((object) => (
            <a href={object.lien} id={object.key} key={object.key}>
              <img
                src={process.env.PUBLIC_URL + "/img/partenaires/" + object.icon}
                target="_blank"
                alt={object.alt}
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

export default Qui;
