export const banner = [
  {
    key: 1,
    text: "Ensemble, réalisons vos ambitions environnementales",
    sousTitre: "Conseil environnemental",
    icon: "banner (1).jpg",
    url:"/NotreMission"
  },
  {
    key: 2,
    text: "Des solutions environnementales positives, impactantes & sur mesure",
    sousTitre: "Ingénierie environnementale",
    icon: "banner (2).jpg",
    url:'/Solutions'
  },
  {
    key: 3,
    text: "Une écologie raisonnée, durable & soutenable",
    sousTitre: "Expertise scientifique",
    icon: "banner (3).jpg",
    url:'/QuiSommeNous'
  },
  {
    key: 4,
    text: "Pas de blabla, pas de Greenwashing",
    sousTitre: "Notre vision",
    icon: "banner (4).jpg",
    url:'/QuiSommeNous'
  },
];
