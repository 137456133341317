import banner1 from "../assets/banner/banner (1).jpg";
import banner2 from "../assets/banner/banner (2).jpg";
import banner3 from "../assets/banner/banner (3).jpg";
import banner4 from "../assets/banner/banner (4).jpg";
import image from "../assets/articles/article1/pb_article.png";
import image2 from "../assets/articles/article1/pb_article2.png";
import panache from "../assets/articles/article1/panache.jpg";
import trans from "../assets/articles/article2/trans.jpeg";
import eco from "../assets/articles/article2/eco.png";
import histo from "../assets/articles/article2/histo.jpg";
import norme from "../assets/articles/article2/norme.png";
import EES from "../assets/articles/article3/EES.png";
import logos from "../assets/articles/article3/logos.png";
import devdurable from "../assets/articles/article3/devdurable.jpeg";
import titre from "../assets/articles/article4/titre.jpeg";
import soustitre1 from "../assets/articles/article4/soustitre1.jpeg";
import soustitre2 from "../assets/articles/article4/soustitre2.jpeg";
import soustitre3 from "../assets/articles/article4/soustitre3.jpeg";
import soustitre4 from "../assets/articles/article4/soustitre4.jpeg";

export const blog = [
  {
    title: "Défis environnementaux\u00a0: de vrais enjeux ?",
    key: "Defis_environnementaux",
    image: image2,
    imageResume: panache,
    resume:
      "Les défis environnementaux auxquels nous sommes confrontés sont nombreux et il est parfois difficile de bien les cerner. Pour mieux se saisir de ces questions, nous revenons sur les publications scientifiques qui ont posé les bases des 9 limites planétaires qui permettent un développement sûr et juste à l’humanité. Dans cet article nous évoquons les solutions qui existent pour répondre à ces défis et en quoi PractiGREEN peut aider les entreprises à développer une transition écologique adaptée. ",
    text: (
      <>
        <h3>Date de publication : 22 novembre 2021</h3>
        <p className="resume">
          Les défis environnementaux auxquels nous sommes confrontés aujourd’hui
          sont le résultat de nos actions passées. Depuis le milieu du XXe
          siècle, les activités humaines ont eu un impact à l‘échelle mondiale
          sur tout le système Terre. En cause, l'utilisation de combustibles
          fossiles pour la production d’énergie, l’agriculture, la déforestation
          ou encore la production industrielle. Ces pratiques ont entrainé des
          changements climatiques et environnementaux majeurs.{" "}
        </p>
        <img
          src={panache}
          alt="Photo d'un panache de fumée"
          style={{ width: "60%", alignSelf: "center" }}
        />
        <p style={{ width: "60%", alignSelf: "center" }}>
          © Sam Jotham Sutharson
        </p>
        <br />
        <br />
        <h1>
          «&nbsp;Nous devons agir pour faire face aux défis
          environnementaux&nbsp;»
        </h1>
        <h2>Oui sans doute, mais de quoi parlons-nous exactement ?</h2>

        <p>
          Pour y voir plus clair, nous pouvons nous appuyer sur la science. En
          2009, une équipe internationale de 26 chercheurs menée par le{" "}
          <a href="https://www.stockholmresilience.org/meet-our-team/staff/2008-01-16-rockstrom.html">
            Pr. Johan Rockström
          </a>{" "}
          (Stockholm University) définit le concept des 9 limites planétaires
          [1]. Ainsi, pour assurer un développement sûr et juste à l’humanité,
          en respectant les ressources et les services écosystémiques rendus,
          nous devons être attentifs à 9 processus environnementaux qui
          délimitent cet espace de développement :
        </p>
        <ul>
          <li> Le changement climatique</li>
          <li> L’érosion de la biodiversité</li>
          <li>
            La perturbation des cycles biogéochimiques de l’azote et du
            phosphore
          </li>
          <li> Les changements d’utilisation des sols</li>
          <li> L’acidification des océans</li>
          <li> L’utilisation mondiale de l’eau</li>
          <li> L’appauvrissement de l’ozone stratosphérique</li>
          <li> L’augmentation des aérosols dans l’atmosphère</li>
          <li> L’introduction de polluants chimiques dans la biosphère</li>
        </ul>
        <p>
          Ce concept offre une vision globale des défis qui sont les notres et
          s’accompagne d’indicateurs qui permettent de suivre la bonne santé de
          notre environnement.
        </p>
        <br />
        <br />
        <h2>
          Alors il suffit de faire attention à ces indicateurs et nous voilà
          sauvés ?{" "}
        </h2>
        <p>
          Oui,… mais non. En 2015, les scientifiques étayent leur analyse et
          publient dans la revue Science un article qui montre que les limites
          planétaires pour assurer un développement sûr et juste ont déjà été
          dépassées pour 3 des 9 critères [2] : perte de la biodiversité,
          perturbation des cycles de l’azote et du phosphore, crise climatique.
          En 2020, différents auteurs semblent montrer que la limite de la
          charge en aérosols dans l’atmosphère est également dépassée même si
          des incertitudes persistent [3].
        </p>
        <img
          style={{ textAlign: "center", alignSelf: "center" }}
          src={image}
          alt="Les neuf limites planétaires"
        />
        <br />
        <br />
        <br />
        <h2>Comment réagir face à tous ces défis environnementaux ? </h2>
        <p>
          Comme 86 % des dirigeants d’entreprise, vous et nous sommes conscients
          des enjeux climatiques et 80 % d’entre nous avons la volonté de
          changer nos pratiques. Pourtant, seule la moitié des entrepreneurs
          déclarent intégrer l’enjeu climatique dans leurs stratégies [4]. C’est
          pourtant là que nous pouvons agir, en tant que citoyens mais aussi
          dans nos entreprises.
        </p>
        <p>
          L’État français s’est saisi de ces questions assez récemment après la
          conférence environnementale sur le développement durable de 2012. Ceci
          a conduit à l’élaboration d’une feuille de route, la création du{" "}
          <a href="https://www.ecologie.gouv.fr/cnte">
            conseil national de la transition écologique (CNTE)
          </a>{" "}
          en 2013 et la mise en place d’une stratégie nationale en 2014.
        </p>
        <p>
          À l’échelle européenne, le{" "}
          <a href="https://ec.europa.eu/info/strategy/priorities-2019-2024/european-green-deal/delivering-european-green-deal_fr">
            pacte vert pour l’Europe
          </a>{" "}
          a vu le jour en 2019 par la rédaction d’une feuille de route pour la
          transition écologique durable. La réglementation française et
          européenne s’adapte également pour inciter les entreprises à franchir
          le pas mais ces initiatives restent récentes et balbutiantes et ne
          permettent pas aux entrepreneurs et aux collectivités d’agir
          concrètement.
        </p>
        <p>
          La transition écologique prône donc la mise en place d’un nouveau
          modèle économique et social pour répondre aux défis environnementaux.
          Elle s’accompagne d’une meilleure utilisation des ressources et passe
          nécessairement par une transition énergétique et sociale. Il s’agit de
          repenser notre façon de vivre, de produire, de travailler afin
          d’intégrer l’environnement et le respect des ressources dans nos
          pratiques. Ce changement s’opère par une modification de nos
          comportements, de nos pratiques sociales.
        </p>
        <br />
        <br />
        <h2>Et PractiGREEN dans tout ça ? </h2>
        <p>
          PractiGREEN, vous vient en aide, à vous, dirigeants d’entreprises et
          décideurs publics pour développer des{" "}
          <a href="https://practigreen.fr/#/Solutions">
            solutions de transition environnementale
          </a>
          . Nous avons la conviction que la transition environnementale doit se
          faire dans le respect des hommes et des femmes pour leur garantir un
          bien-être au travail et dans leur vie de tous les jours. Cette
          transition doit accompagner le développement économique de
          l’entreprise en mettant l’environnement au cœur de sa stratégie.
        </p>
        <p>
          Nous développons avec vous des solutions à fort impact positif qui
          prennent en compte le fonctionnement et la structure de votre
          organisation pour créer une chaîne de valeur durable et éthique.
          Mettre l’environnement au cœur de sa stratégie implique un changement
          des pratiques de gestion de l’eau, des sols, du respect de la
          biodiversité, d’une mobilité durable, d’un engagement RSE sur lesquels
          PractiGREEN peut vous accompagner, sans greenwashing.
        </p>
        <br />
        <br />
        <h1>
          <a href="https://practigreen.fr/#/QuiSommeNous">
            «&nbsp;Nos convictions nous poussent à agir et à être au plus près
            de vos organisations pour vous proposer des solutions
            environnementales concrètes, qui répondent à vos attentes.&nbsp;»
          </a>
        </h1>
        <br />
        <br />
        <br />
        <br />
        <h3>Références : </h3>
        <h3>
          1. Rockström, J. et al. Planetary Boundaries: Exploring the Safe
          Operating Space for Humanity. Ecology and Society 14, (2009).{" "}
        </h3>
        <h3>
          2. Steffen, W. et al. Planetary boundaries: Guiding human development
          on a changing planet. Science 347, 1259855 (2015).{" "}
        </h3>
        <h3>
          3. BOUTAUD, A. & GONDRAN, N. Les limites planétaires. (La Découverte,
          2020).
        </h3>
        <h3>
          4. BPI France Le Lab. Les dirigeants de PME-ETI face à l’urgence
          climatique. (2020).{" "}
        </h3>
      </>
    ),
    banner: <img src={banner1} alt="bannière de l'article 1" />,
  },
  {
    title: "Transition écologique : transformer le rêve en réalité",
    key: "Transition_ecologique",
    image: image2,
    imageResume: trans,
    resume:
      "La réponse aux changements climatiques passe nécessairement par un changement des pratiques et des mentalités, c’est ainsi que la transition écologique de la société se met en place. Elle est encouragée par les gouvernements et institutions européennes et mondiales mais sa mise en place peut être compliquée. PractiGREEN vous vient en aide pour vous aider à initier une transition écologique en douceur, en vous conseillant et en vous accompagnant dans vos démarches. ",
    text: (
      <>
        <h3>Date de publication : 30 novembre 2021</h3>
        <p className="resume">
          Un consensus sociétal et scientifique s’impose aujourd’hui à nous : il
          est nécessaire de changer nos habitudes, nos pratiques et nos modes de
          consommation. De manière générale, on regroupe ces changements sous
          l’appellation « transition écologique »{" "}
        </p>
        <img
          src={trans}
          alt="la transition écologique"
          style={{ width: "60%", textAlign: "center", alignSelf: "center" }}
        />
        <br />
        <br />
        <h2>
          L’espoir du changement : qu’est-ce que la transition écologique ?
        </h2>

        <p>
          Le concept de transition écologique porte bien son nom : il n’est pas
          une fin en soi, mais indique une période d’adaptation… mais une
          adaptation de quoi, et à quoi ? <br />
          <br />
          Ce concept a notamment été théorisé par le{" "}
          <a href="https://transitionnetwork.org/people/rob_hopkins/">
            Pr. Rob Hopkins
          </a>{" "}
          [1]. Celui-ci travaillait sur des modèles de résilience et d’autonomie
          à l’échelle locale. Il étendit ensuite le périmètre de ses conclusions
          afin de proposer des principes applicables aux villes, pour faire face
          aux changements climatiques et à la sur-dépendance au pétrole (entre
          autres énergies fossiles…). Aujourd’hui, ce concept vise à proposer un
          nouveau modèle économique et social permettant de répondre aux enjeux
          écologiques de notre siècle. Ce modèle participerait à une refonte de
          nos façons de consommer, de produire, de travailler et de vivre
          ensemble. Tout ceci afin de répondre aux défis climatiques et
          environnementaux que confrontent nos sociétés :
        </p>
        <ul>
          <li> Perte de la biodiversité</li>
          <li> Dérèglement climatique</li>
          <li>
            Raréfaction des ressources (notamment les ressources en eau, les
            métaux rares…)
          </li>
          <li> Risque sanitaire</li>
        </ul>
        <p>
          Souvent, la notion de transition écologique est confondue avec celle
          de transition énergétique. La transition énergétique concerne
          notamment :
        </p>
        <ul>
          <li> La sobriété énergétique</li>
          <li> La production d’énergie</li>
          <li>
            L’utilisation d’énergies renouvelables plutôt que d’énergies
            carbonées
          </li>
        </ul>
        <p>
          La transition écologique recouvre la transition énergétique et bien
          d’autres périmètres :
        </p>
        <ul>
          <li> Transition industrielle</li>
          <li> Transition agro-alimentaire</li>
          <li> Transition sociale </li>
          <li> Transition économique </li>
          <li> Transition politique </li>
          <li> Transition de la mobilité et des transports </li>
          <li> Et de façon générale tout sujet touchant à l’environnement… </li>
        </ul>
        <br />
        <img
          style={{ alignSelf: "center" }}
          src={eco}
          alt="Une transition écologique à multiples facette"
        />
        <p>
          De manière synthétique, cette transition permettrait de rendre notre
          société plus écologique, ou autrement dit, faire le lien entre notre
          société et l’environnement.
          <br /> Ainsi, ce concept de transition est résolument optimiste : en
          opposition totale avec la résignation et le renoncement que l’on
          pourrait avoir… il y a une conscience des enjeux et des problèmes,
          mais nous pouvons (et devons !) trouver des solutions pour y faire
          face. <br />
          <br />
          Un joli rêve oui… mais qu’en est-il vraiment ?
        </p>
        <br />
        <h2>Retour à la réalité : la transition écologique en application</h2>
        <p>
          La transition écologique est donc un changement de paradigme,
          nécessitant des actions concrètes.
          <br />
          <br /> Face à cela, les gouvernements successifs ont entrepris
          différentes « actions » (dont voici un historique non exhaustif depuis
          2010) :<br />
          <br />
          Définition de feuilles de routes portant sur la biodiversité, la
          transition énergétique, les risques sanitaires et environnementaux…
          <a href="https://www.ecologie.gouv.fr/conference-environnementale">
            (conférences environnementale sur le développement durable, 2012 -
            2016),
          </a>{" "}
          mise en place de la{" "}
          <a href="https://www.ecologie.gouv.fr/strategie-nationale-transition-ecologique-vers-developpement-durable-2015-2020">
            Stratégie Nationale de Développement Durable (SNDD, 2010-2013)
          </a>{" "}
          et{" "}
          <a href="https://www.ecologie.gouv.fr/cnte">
            création du Conseil National de la Transition Écologique (CNTE)
          </a>{" "}
          en 2013 [2].
          <br />
          Le CNTE travaillera notamment sur la création d’une{" "}
          <a href="https://www.ecologie.gouv.fr/strategie-nationale-transition-ecologique-vers-developpement-durable-2015-2020">
            Stratégie Nationale de Transition Écologique vers un Développement
            Durable (SNTEDD, 2014-2020 [3])
          </a>{" "}
          reposant sur une consultation publique citoyenne et amendée des
          conclusions du CNTE. Cette stratégie est censée pouvoir répondre aux
          défis environnementaux et donner les clés d’une transition écologique
          réussie et durable. On note même un texte de loi, promulgué en 2015{" "}
          <a href="https://www.legifrance.gouv.fr/dossierlegislatif/JORFDOLE000029310724/">
            (loi n° 2015-922 [4])
          </a>{" "}
          portant sur la transition énergétique, associée à une croissance
          verte, en France.
          <br />
          <br />À plus grande échelle, la commission européenne a instauré en
          2019{" "}
          <a href="https://ec.europa.eu/info/strategy/priorities-2019-2024/european-green-deal/delivering-european-green-deal_fr">
            le pacte Vert pour l’Europe (European Green Deal)
          </a>{" "}
          [5], avec pour objectif de donner les clés aux citoyens et aux
          organisations européennes, d’une transition écologique durable et
          soutenable. Et au-delà de ça, différents Grenelles, COP, etc. ont
          présenté des travaux visant à rendre nos sociétés plus « vertes ».{" "}
          <br />
          <br />
          Ces travaux ont du sens (personne ne dira le contraire), mais le
          nombre et la difficulté d’interprétation de ces textes et autres
          stratégies rendent toute action concrète difficilement réalisable à
          l’échelle de vos organisations.
          <br />
          <br /> Ainsi, la transition écologique est une belle idée, mais à ce
          jour difficilement applicable… Alors comment aller plus loin ?
        </p>
        <img
          className="desktop"
          style={{ textAlign: "center", width: "100%" }}
          src={histo}
          alt="Historique non exhaustif de mesures écologiques prises en France et en Europe"
        />
        <h3 className="desktop" style={{ textAlign: "center" }}>
          Historique non exhaustif de mesures écologiques prises en France et en
          Europe
        </h3>
        <br />
        <br />
        <br />
        <h2>
          Aller plus loin : vos ambitions & notre vision pour la transition
          écologique{" "}
        </h2>
        <p>
          Changement climatique, perte de la biodiversité, changements
          environnementaux… La transition écologique de notre société est
          aujourd’hui un enjeu majeur, mais les démarches apparaissent complexes
          et chronophages pour les entreprises et les collectivités.
        </p>
        <p>
          L’écologie est trop souvent perçue comme culpabilisante. Or, nous
          sommes convaincus qu’une transition écologique efficace devra
          nécessairement passer par une évolution des mentalités et des
          pratiques. Pourtant, les solutions proposées apparaissent souvent
          contraignantes ou trop généralistes.
        </p>
        <p>
          De plus, votre organisation fait partie d’un écosystème complexe, et
          il est nécessaire que vos démarches de transition écologique puissent
          intéresser, concerner et impliquer l’ensemble de vos parties
          prenantes. Ceci vous permettra de développer une stratégie de
          développement durable et soutenable dans le temps, dans le respect de
          vos collaborateurs, clients et partenaires.
        </p>
        <img
          style={{ textAlign: "center", alignSelf: "center" }}
          src={norme}
          alt="schéma de la norme iso26000"
        />
        <h3 style={{ textAlign: "center" }}>
          Les 7 questions au cœur des démarches RSE et de la transition
          écologique, issues de la norme ISO 26000
        </h3>
        <p>
          Ainsi, afin de pouvoir répondre à vos interrogations et besoins, nous
          appuyons{" "}
          <a href="https://practigreen.fr/#/NotreMission">notre démarche</a> sur
          des outils et réglementations comme la{" "}
          <a href="https://www.iso.org/fr/iso-26000-social-responsibility.html">
            norme ISO 26000
          </a>{" "}
          . Ceci nous permet une approche globale de la transition écologique,
          et de respecter l’ensemble de vos parties prenantes.
        </p>
        <p>
          Pour finir, transition écologique ne rime pas avec « décroissance ».
          La technologie et les avancées de nos sociétés nous permettent
          aujourd’hui d’avoir accès à un important confort de vie, qui doit être
          préservé, en accord avec la sauvegarde environnementale. Ces avancées
          technologiques peuvent aussi permettre d’avancer vers un modèle de
          société durable, respectueux de l’environnement et de l’humain. Les
          aspects sociaux et économiques sont donc indissociables de nos
          services.
        </p>
        <h1>
          <a href="https://practigreen.fr/#/QuiSommeNous">
            « Ainsi, avec PractiGREEN, nous portons la conviction que le
            développement écologique peut, et doit, aller de pair avec le
            développement économique et la hausse du confort de vie et de
            travail de nos clients. »
          </a>
        </h1>
        <br />
        <br />
        <br />
        <br />
        <h3>Références : </h3>
        <h3>
          1. Hopkins, R. & Heinberg, R. The transition handbook: from oil
          dependency to local resilience. Chelsea Green Publishing 320 (2008).{" "}
        </h3>
        <h3>
          2. Le conseil national de la transition écologique. Ministère de la
          Transition écologique https://www.ecologie.gouv.fr/cnte.{" "}
        </h3>
        <h3>
          3. Ministère de l’Écologie, & du Développement durable et de
          l’Énergie. Avant-projet de stratégie nationale de transition
          écologique vers un développement durable 2014-2020. (2014).
        </h3>
        <h3>
          4. LOI n° 2015-992 du 17 août 2015 relative à la transition
          énergétique pour la croissance verte. 2015-992 (2015).{" "}
        </h3>
        <h3>
          5. Commission au parlement européen. Pacte Vert pour l’Europe. (2019).{" "}
        </h3>
      </>
    ),
    banner: <img src={banner2} alt="bannière de l'article 2" />,
  },
  {
    title: "Écologie, économie et social : une équation compliquée",
    key: "developpement_durable",
    image: image2,
    imageResume: devdurable,
    resume:
      "La transition écologique doit concilier les enjeux économiques, environnementaux et sociaux mais l’équation est parfois compliquée. Dans cet article, nous expliquons pourquoi il est absolument nécessaire de concilier ces 3 enjeux.  Nous abordons les fondamentaux qui ont conduit à la définition du développement durable et de la RSE. La RSE est un outil puissant pour développer une transition écologique si la stratégie est bien maîtrisée et peut avoir de nombreux bénéfices pour l’entreprise.) ",
    text: (
      <>
        <h3>Date de publication : 01 décembre 2021</h3>
        <img
          src={devdurable}
          alt="la transition écologique"
          style={{ width: "50%", textAlign: "center", alignSelf: "center" }}
        />
        <br />
        <br />
        <p>
          La croissance économique implique, à différents niveaux, une
          utilisation des ressources (eau, minerais, matières organiques etc.).
          La production agricole, la production industrielle ou encore les
          transports vont avoir un impact sur notre environnement : <br />
        </p>
        <ul>
          <li> Émissions de GES (Gaz à effet de serre)</li>
          <li> Émissions de particules fines</li>
          <li> Pollutions chimiques</li>
          <li> Production de déchets</li>
        </ul>
        <p>
          Face aux enjeux climatiques et environnementaux, les entreprises se
          saisissent de ces questions et souhaitent y répondre{" "}
          <a href="https://lelab.bpifrance.fr/Etudes/les-dirigeants-de-pme-eti-face-a-l-urgence-climatique">
            (BPI France, 2020 [1])
          </a>{" "}
          . La prise en compte des enjeux environnementaux ne peut cependant pas
          se faire au détriment de la santé économique d’une entreprise ni au
          détriment de ses employés. <br />
        </p>
        <br />
        <br />
        <h2>
          Comment concilier écologie, économie et enjeux sociaux dans la
          stratégie de votre organisation ?
        </h2>

        <p>
          Dans les années 1960, le concept de responsabilité des entreprises
          face aux enjeux sociaux et environnementaux s’est développé (The
          Responsible Compagny [2]). Aujourd’hui, toutes ces préoccupations ont
          permis la naissance de la responsabilité sociétale des entreprises
          (RSE). <br /><br />
        <br />
          La RSE, comme définie par la commission européenne, est :
        </p>
        <p className="resume" style={{ textAlign: "center" }}>
          « L’intégration volontaire par les entreprises de préoccupations
          sociales et environnementales à leurs activités commerciales et leurs
          relations avec les parties prenantes »{" "}
        </p>
        <p>
          Le développement d’une stratégie RSE permet ainsi à une entreprise de
          se questionner sur ses pratiques et de définir des objectifs
          réalisables pour répondre aux enjeux environnementaux et sociaux.
        </p>
        <p>
          Le{" "}
          <a href="https://www.are.admin.ch/are/fr/home/media-et-publications/publications/developpement-durable/brundtland-report.html">
            rapport Brundtland
          </a>{" "}
          publié en 1987 [3] introduit pour la première fois la notion du
          développement durable. Le développement durable ne peut avoir lieu
          qu’à la condition que les enjeux environnementaux, sociaux et
          économiques soient pris en compte. Ceci serait la condition <span style={{fontStyle:'italic'}}>sine qua
          non </span>pour un développement durable, mais surtout pour un développement
          soutenable.
        </p>
        <h1>
          <a href="https://www.practigreen.fr/#/QuiSommeNous#vision">
            « Le développement durable est un développement qui répond aux
            besoins du présent sans compromettre la possibilité, pour les
            générations à venir, de pouvoir répondre à leurs propres besoins. »
          </a>
        </h1>
        <img
          style={{ alignSelf: "center", width: "50%" }}
          src={EES}
          alt="Schéma du développement durable"
        />
        <h3 style={{ textAlign: "center" }}>
          Développement durable : « un développement qui répond aux besoins du
          présent sans compromettre la capacité des générations futures à
          répondre aux leurs », citation de Mme Gro Harlem Brundtland, Premier
          Ministre norvégien (1987) . Dans un monde idéal, la convergence entre
          les enjeux économiques, sociaux et écologiques serait totale. Adapté
          du Rapport Brundtland, 1987
        </h3>
        <p>
          Le développement d’une stratégie RSE est donc une solution pour
          concilier les enjeux environnementaux, sociaux et économiques. Les
          entreprises peuvent notamment s’appuyer sur l’
          <a href="https://www.iso.org/fr/iso-26000-social-responsibility.html">
          ISO 26000
          </a>
          , fruit de 5 ans de recherche par 99 pays, et qui constitue le
          principal standard international de Responsabilité Sociétale, afin de
          définir des objectifs :
        </p>
        <ul>
          <li>
            {" "}
            Sociaux : assurer le bien-être de leurs employés, le respect de
            leurs droits, de leur{" "}
            <a href="https://www.who.int/fr/about/governance/constitution">
              santé
            </a>{" "}
          </li>
          <li>
            {" "}
            Environnementaux : réduction des émissions de GES, recyclage,
            partenaires et fournisseurs éco-responsables, développer une
            activité durable
          </li>
          <li>
            {" "}
            Economiques : soutenir l’économie locale, investir dans sa région,
            transparence auprès de ses investisseurs et clients
          </li>
        </ul>
        <p>
          Cependant, la stratégie RSE n’est pas une fin en soi, elle n’est qu’un
          outil qui doit servir à montrer la volonté de changement de
          l’entreprise pour s’approprier les enjeux d’une économie durable. Les
          objectifs annoncés dans les rapports RSE (ou rapports extra-financiers)
          doivent être atteints et la démarche suivie pour qu’elle soit une
          réussite dans un processus de transition écologique.
        </p>
        <p>
          Dans le contexte actuel, une entreprise qui se saisit de ces questions
          et montre un engagement fort dans les questions environnementales et
          sociales a un avantage indéniable sur ses concurrents : une entreprise
          engagée dans la RSE est perçue comme une entreprise avec un impact
          positif sur la société par 83% de ses employés contre 59% pour les
          entreprises non engagées{" "}
          <a href="https://www.medef.com/fr/actualites/barometre-de-perception-de-la-rse-en-entreprise">
            (Baromètre de la perception de la RSE, MEDEF, 2020 [4])
          </a>
          . Les jeunes talents sont également sensibles à ces questions et
          préfèrent travailler pour les entreprises engagées dans les questions
          sociales et environnementales, leur sentiment d’appartenance à
          l’entreprise étant renforcé.
        </p>
        <p>
          De manière générale, 47% des salariés indiquent que l’environnement
          est une priorité pour eux mais ils ont le sentiment que ce n’est pas
          le cas pour leur direction (27 % des sondés estiment que
          l’environnement n’est pas une priorité pour leur direction). La
          question de l’environnement est donc une opportunité de mobilisation
          des collaborateurs autour d’une thématique commune{" "}
          <a href="https://www.medef.com/fr/actualites/barometre-de-perception-de-la-rse-en-entreprise">
            (Baromètre de la perception de la RSE, MEDEF, 2020 [4])
          </a>
          .
        </p>
        <p>
          L’engagement des entreprises vers une démarche de transition
          écologique est essentiel pour répondre à ces problèmes sociétaux et
          assurer une activité durable en changeant le mode de fonctionnement de
          l’entreprise et les mentalités des parties prenantes.
        </p>
        <h2>
          Des alternatives à la transition écologique de mon entreprise
          existent-elles ?
        </h2>
        <p>
          Un mouvement politique, économique et social est né de ces
          interrogations : la décroissance économique. Le concept se base sur
          l’idée qu’une croissance infinie dans un monde fini, aux ressources
          limitées, n’est pas possible et que la richesse économique ne doit
          plus augmenter voire diminuer pour pouvoir assurer la survie sur le
          long terme de l’humanité. Ce concept prône également l’égalité sociale
          par un réajustement des rapports Nord/Sud pour assurer un développer
          égalitaire et équitable.
        </p>
        <p>
          Chez PractiGREEN nous pensons qu’une décroissance économique n’est pas
          la solution. Une transition écologique bien planifiée et bien réalisée
          doit vous permettre de concilier développement économique, mesures
          sociales éthiques et respect de l’environnement.
        </p>
        <p>
          PractiGREEN apporte des solutions environnementales aux entreprises
          prêtes à s’engager et à réagir face à l’urgence climatique en
          analysant vos besoins et en vous proposant des solutions
          environnementales fortes pour réduire vos impacts sur l’environnement
          (gestion de l’eau, du sol, transports, déchets, sensibilisation à
          l’environnement). Nous vous aidons, entrepreneurs et décideurs
          publics, à vous saisir de ces questions et à définir des objectifs
          réalistes puis nous vous accompagnons dans la mise en place de votre
          stratégie.
        </p>
        <p>
          PractiGREEN s’est associé à{" "}
          <a href="https://www.narrau.com/">NARRAU</a>, une entreprise portée
          par deux docteures en sciences sociales afin de pouvoir intégrer les
          aspects sociaux à nos démarches de transition écologique. Nous nous
          assurons ainsi que les solutions que nous proposons sont adaptées à
          l’entreprise et à ses collaborateurs et qu’elles s’intègrent
          parfaitement dans une stratégie de bien-être au travail, du respect
          des femmes & des hommes et de leurs droits.
        </p>
        <a
          href="https://www.narrau.com/"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <img
            style={{ alignSelf: "center" }}
            src={logos}
            alt="Partenariat PractiGREEN - NARRAU"
          />
        </a>
        <br />
        <br />
        <br />
        <br />
        <h3>Références : </h3>
        <h3>
          1. BPI France Le Lab. Les dirigeants de PME-ETI face à l’urgence
          climatique. (2020).{" "}
        </h3>
        <h3>2. Goyder, G. Responsible Compagny. (1961). </h3>
        <h3>
          3. Brundtland, G. H. Notre avenir à tous, Rapport de la Commission
          mondiale sur l’environnement et le développement de l’ONU. (1987).
        </h3>
        <h3>4. MEDEF. Baromètre national de perception de la RSE. (2020). </h3>
      </>
    ),
    banner: <img src={banner3} alt="bannière de l'article 3" />,
  },
  {
    title: "Greenwashing : le mode d'emploi",
    key: "greenwashing",
    image: image2,
    imageResume: titre,
    resume:
      "Mode d’emploi du greenwashing : utilisation de la couleur verte, détournement d’attention, manque de transparence, faux label. Nous vous livrons tous les secrets d’un greenwashing réussi. ",
    text: (
      <>
        <h3>Date de publication : 07 décembre 2021</h3>
        <p className="resume">
          Le greenwashing (ou verdissement en français) se définit comme une
          pratique marketing qui a pour but de verdire l’image d’une marque ou
          d’une entreprise, à travers une stratégie de communication qui utilise
          l’argument de l’environnement de façon trompeuse, en déformant la
          réalité ou en omettant certaines informations. Le greenwashing peut
          être utilisé aussi bien sur le fond que sur la forme.
          <br />
          Dans ce guide, PractiGREEN vous prodigue des astuces et des conseils
          pour mettre en place une stratégie de greenwashing efficace, de
          manière peu éthique et peu responsable afin de ne pas vous engager
          dans une démarche de transition écologique forte qui témoignerait de
          vos engagements à l’égard de la société.{" "}
        </p>
        <img
          src={titre}
          alt="la transition écologique"
          style={{ width: "50%", textAlign: "center", alignSelf: "center" }}
        />
        <br />
        <br />
        <br />
        <br />
        <h2>1. La reine les aime rouges, peignons les en vert !  </h2>
        <p>
          Dans l’inconscient (ou le conscient) collectif, le vert est associé
          aux arbres, aux plantes et par extension à tout ce qui touche au
          naturel et à l’environnement. Mettre du vert pour rappeler
          l’environnement, c’est bien et nécessaire, mais ici, pour faire un bon greenwashing,
          il va falloir abuser de cette couleur : du vert sur le packaging des
          produits, du vert dans la communication, du vert sur vos bâtiments…Mieux ! Habillez-vous en vert !<br />
          Bref, on veut voir du vert partout. Si le vert est associé à des
          images et des logos qui rappellent la nature (arbres, fleurs, paysages
          ruraux), le produit ou la marque seront ancrés dans le naturel dans
          l’esprit des consommateurs. La forme n’est pas le seul angle
          d’attaque, le discours est également important et il est primordial
          d’adopter un champ lexical de la nature ou qui fait appel aux
          sentiments : « 100 % naturel », « nature », « respecte l’environnement
          », « fait avec amour », « fait pour vous ».
        </p>
        <img
          style={{ alignSelf: "center", width: "50%" }}
          src={soustitre1}
          alt="Levert"
        />
        <br />
        <br />
        <br />
        <br />
        <h2>2. Le détournement d’attention</h2>
        <p>
          Pour cacher l’impact environnemental d’un produit ou d’un service, le
          détournement d’attention est très efficace. Il s’agit par exemple de
          développer une seule gamme de produits ou de services eco-responsables
          et de ne pas chercher à adapter ses pratiques quand celles-ci ne sont
          pas durables et/ou qu’elles ne s’inscrivent pas dans une volonté de
          réduction de vos impacts environnementaux. Comme un magicien qui vous
          invite à regarder une main vide pour détourner votre attention, alors
          que tout se passe dans l’autre main.
        </p>
        <img
          style={{ alignSelf: "center", width: "50%" }}
          src={soustitre2}
          alt="Le détournement d'attention"
        />
        <br />
        <br />
        <br />
        <br />
        <h2>3. Le manque de transparence</h2>
        <p>
          Cette étape n’est pas la plus aisée, il s’agit de communiquer
          efficacement pour faire connaître vos actions, vos engagements et vos
          objectifs sociaux et environnementaux mais de façon suffisament opaque
          pour ne pas respecter les engagements que vous annoncez. La première
          étape, vous l’aurez compris est de dire que vous vous engager :
          réduction des émissions de gaz à effet de serre, énergies
          renouvelables, productions écoresponsables, objectifs sociaux (parité
          H/F, salaire, bien-être au travail), un seul conseil faîtes-vous
          plaisir ! Plus vous vous engagez, plus vous fédérez ! Montrez vos
          ambitions et définissez des objectifs ambitieux à atteindre à des
          dates précises.
        </p>
        <p>
          Le conseil de l’expert : choisissez tout de même une date suffisament
          loin pour ne pas avoir de problèmes pour l’instant (vous allez
          comprendre pourquoi).
        </p>
        <p>
          Lorsque vos objectifs sont définis, soyez évasifs sur les actions que
          vous menez pour les atteindre : vous investissez dans les énergies
          renouvelables – oui mais ne communiquez pas sur les chiffres – ; vos
          produits sont faits en matière recyclées – oui mais ne dites pas
          qu’ils ne contiennent que 10% de coton recyclé – ; le score de
          bien-être au travail de vos employés est très élevé – évitez de
          mentionnez qu’ils ne restent pas plus de 6 mois dans l’entreprise–.
        </p>
        <p>
          La technique du mensonge (mensonge frontal ou mensonge par omission)
          se rapproche de la technique du manque de transparence et ne sera donc
          pas abordé dans ce guide.
        </p>
        <img
          style={{ alignSelf: "center", width: "50%" }}
          src={soustitre3}
          alt="Le manque de transparence"
        />
        <br />
        <br />
        <br />
        <br />
        <h2>4. Le Le faux label</h2>
        <p>
          Cette étape n’est pas conseillée pour les débutants en greenwashing
          puisqu’elle demande une équipe de communicants et de juristes
          qualifiés. Cette étape consiste à créer un faux label. On ne parle pas
          ici d’un label comme NF environnement, de l’Écolabel européen, du
          label AB agriculture biologique. Il s’agit d’apposer sur vos produits
          un logo ou une mention qui fait penser à un label certifié et qui va
          tromper vos clients dans l’idée que ce que vous leur proposez est
          certifié par un organisme indépendant qui s’assurerait de votre bonne
          foi et de votre volonté à développer une activité économique
          écoresponsable et éthique.
        </p>
        <img
          style={{ alignSelf: "center", width: "50%" }}
          src={soustitre4}
          alt="Le faux label écologique"
        />
        <br />
        <br />
        <p>
          C’est ici que se conclut ce petit guide destiné aux entrepreneurs qui
          veulent s’engager dans une démarche de greenwashing.
        </p>
        <br />
        <br />
        <div style={{width:"100%", borderBottom:"#66a46e solid 2px"}}></div>
        <br />
        <br />
        <p>
          PractiGREEN, entreprise de conseils en transition écologique éthique, refuse
          de tomber dans les facilités du greenwashing et dénonce ces
          techniques.
        </p>
        <h1>
          <a href="https://www.practigreen.fr/#/Contact">
            Contactez-nous pour ne pas tomber dans ces pièges 
          </a>
        </h1>
        <p>
          PractiGREEN s’appuie sur ses experts scientifiques pour proposer des
          solutions environnementales à fort impact positif qui s’adaptent à
          votre entreprise, à votre territoire. En lien avec nos partenaires, nous nous assurons que nos
          solutions conviennent à vos employés et qu’elles s’inscrivent dans une
          volonté de bien-être au travail et de respect des droits et de la
          santé de vos collaborateurs et collaboratrices.
        </p>
        <br />
        <br />
        <br />
        <br />
      </>
    ),
    banner: <img src={banner4} alt="bannière de l'article 4" />,
  },
];

export function getBlog() {
  return blog;
}

export function getArticle(key) {
  return blog.find((invoice) => invoice.key === key);
}
