export const solutions = [
  {
    title: "Réduire vos impacts environnementaux",
    key: "impacts",
    image: "env",
    item: [
      {
        title:
          "Gestion des sols : perméabilisation, maitrise de l’érosion, dépollution ",
        key: "sol",
        descrition: (
          <p>
            Le sol est un écosystème à part entière fortement lié aux
            écosystèmes terrestres et aquatiques. Il rend des services
            écosystémiques très importants pour la survie de l’humanité et sa
            sauvegarde est essentielle :
            <br />
            <li>
              Le sol contribue à 95% de la production alimentaire mondiale
            </li>
            <li>
              Le sol permet aux végétaux de se développer en apportant de l’eau
              et des nutriments
            </li>
            <li>
              Le sol est le siège d’une grande biodiversité animale (petits
              mammifères, insectes, vers, bactéries) et végétale
            </li>
            <li>
              Le sol est un acteur clé de différents cycles biogéochimiques
              (carbone, azote, potassium, calcium, phosphore et différents
              métaux)
            </li>{" "}
            <br />
            Un bon état chimique et physique du sol permet de lutter contre
            l’érosion, les inondations, les pertes de carbone, la diminution de
            la fertilité.
            <br />
            <br />
            Le sol est un système complexe et sa bonne qualité dépend de
            nombreux paramètres :<li> Composition en matière organique</li>
            <li>Fraction minérale</li>
            <li> Densité</li>
            <li> Texture</li>
            <li> Porosité</li>
            <br />
            <em>
              <li>
                Nos experts en sciences du sol vous aident à trouver des
                solutions pour lutter contre l’imperméabilisation du sol afin de
                retrouver une maitrise de l’infiltration de l’eau et éviter les
                inondations sur une parcelle ou un territoire donné.
              </li>
            </em>
            <br /> <br />{" "}
            <em>
              <li>
                {" "}
                PractiGREEN vous aider à identifier et mesurer vos impacts sur
                le sol en fonction de votre activité :
              </li>
            </em>
            <li> Pollution aux métaux dans l’industrie</li>
            <li> Imperméabilisation du sol en milieu urbain</li>
            <li> Diminution de la biodiversité</li>
            <li> Diminution de la porosité liée aux charges lourdes</li>
          </p>
        ),
        icon: "sol.png",
        border: false,
      },
      {
        title:
          "Mesure d’impacts, gestion & protection de la ressource en eau et des milieux aquatiques ",
        key: "eau",
        descrition: (
          <p>
            L’eau est une ressource essentielle dont la protection est cadrée
            par la Directive-Cadre sur l’Eau (DCE) qui fixe des objectifs de
            « bon état » des masses d’eau afin de garantir une gestion
            soutenable de cette ressource vitale.
            <br /> <br /> Les entreprises sont toutes soumises à différentes
            réglementations selon leurs secteurs d’activité :
            <li>Législation ICPE</li>
            <li>Loi sur l’Eau</li>
            <li>Règlement Sanitaire Départemental</li>
            <li>Code de la Santé Publique</li>
            <li>Code de l’environnement</li>
            <li>Règlement d’assainissement de la collectivité.</li>
            <br /> <br />
            <em>
              <li>
                PractiGREEN vous aide à identifier les sources potentielles de
                pollution liées à votre activité afin d’anticiper et de prévenir
                les risques de pollution.
              </li>
              <br />
              <li>
                PractiGreen analyse et dresse le bilan de vos impacts sur la
                ressource en eau (consommation, rejets).
              </li>
              <br />
              <li>
                PractiGREEN vous aide à développer une stratégie de gestion de
                l’eau à l’échelle de votre organisation (réduction de la
                consommation, traitement des eaux usées, réduction des rejets
                dangereux) en impliquant toutes les parties prenantes.
              </li>
            </em>
          </p>
        ),
        icon: "aqua.png",
        border: true,
      },
      {
        title:
          "Mesure d’impacts des activités sur la biodiversité & protection et sauvegarde de la biodiversité",
        key: "biodiversite",
        descrition: (
          <p>
            En 2019, la Plateforme intergouvernementale sur la biodiversité et
            les services écosystémiques (IPBES) a alerté sur le declin de la
            biodiversité et appelle tous les acteurs publics et privés à réagir
            pour protéger le patrimoine naturel.
            <br />
            <br /> La réglementation oblige certaines entreprises à rédiger une
            déclaration de performance extra-financière et à y inclure la
            biodiversité : <br />
            <li>Risques liés à l’activité</li>
            <li>
              Politiques mises en œuvre pour prévenir, identifier et atténuer
              les risques
            </li>
            <li>indicateurs clés de performances (ICP)</li>
            <br />
            <br /> Les entreprises doivent intégrer les enjeux de biodiversité
            dans leur stratégie en s’engageant et en analysant leur impact et en
            mettant en œuvre des politiques pour lutter contre la déforestation
            importée.
            <br /> <br />
            <em>
              <li>
                PractiGREEN s’engage avec vous afin d’identifier les impacts de
                votre activité sur la biodiversité et trouver et mettre en œuvre
                des solutions pour limiter les risques inhérents à votre
                activité.
              </li>
            </em>
          </p>
        ),
        icon: "biodiversité.png",
        border: false,
      },
    ],
  },
  {
    title: "Vous intégrer dans un circuit collaboratif",
    key: "collaboratif",
    image: "garden",
    item: [
      {
        title: "Mutualisation et création d’initiatives durables ",
        key: "initiative",
        descrition: (
          <p>
            La création d’initiatives locales et durables se développe en France
            dans différents domaines :
            <li>Gestion des déchets </li>
            <li>Production en circuit court </li>
            <li>Distribution en circuit court </li>
            <br /> Ces initiatives participent à développer l’économie locale et
            circulaire.
            <br />
            <br />
            <em>
              <li>
                {" "}
                PractiGREEN s'engage auprès des porteurs de projets afin
                d’assurer la mise en relation des différents acteurs impliqués
                pour développer des solutions d’économie circulaire et durable.
              </li>
              <br />
              <li>
                PractiGREEN identifie les contraintes environnementales liées à
                vos projets et trouve des solutions durables et respectueuses de
                l’environnement en impliquant les acteurs locaux publics et
                privés.
              </li>
            </em>{" "}
          </p>
        ),
        icon: "durable.png",
        border: false,
      },
      {
        title: "Communication, sensibilisation & coaching",
        key: "coaching",
        descrition: (
          <p>
            Pour mener à bien vos objectifs de transition écologique et de
            développement durable, il est nécessaire d’impliquer toutes vos
            parties prenantes.
            <br />
            <br /> La communication et la sensibilisation aux enjeux climatiques
            de vos employés, fournisseurs et partenaires permet de fédérer toute
            la chaîne de création de valeur autour d’un projet dans lequel
            toutes les parties se sentent concernées et impliquées.
            <br /> <br />
            <em>
              <li>
                {" "}
                PractiGREEN et ses experts habitués aux missions d’enseignement,
                de communication en congrès sur différents supports mettent leur
                expertise au service de votre projet afin de :
              </li>
            </em>{" "}
            <li>
              Sensibiliser toutes vos parties prenantes aux questions
              environnementales
            </li>
            <li>
              {" "}
              Communiquer sur l’utilité de la mise en place de nouvelles
              démarches et méthodes de travail et les bénéfices qui en découlent
              pour l’entreprise et ses employés.
            </li>
          </p>
        ),
        icon: "formation.png",
        border: true,
      },
      {
        title: "Jardins et potagers collaboratifs & pédagogiques ",
        key: "jardin",
        descrition: (
          <p>
            PractiGREEN souhaite encourager les initiatives locales à
            destination du grand public qui se développent de plus en plus en
            France :
            <li>Jardins pédagogiques</li>
            <li>Potagers collectifs</li>
            <li>Composteurs communs</li>
            <li>...</li>
            <br /> Nous pensons qu’il n’y a pas de petites actions et nous
            souhaitons participer à la bonne réalisation de ces initiatives.
            <br />
            <br />
            <em>
              <li>
                {" "}
                PractiGREEN vous accompagne pour la réalisation de vos projets :
              </li>
            </em>
            <li>
              {" "}
              En définissant avec vous les bonnes méthodes et les bons
              prestataires
            </li>
            <li>
              En mettant en place une communication efficace autour de votre
              solution pour fédérer les usagers
            </li>
            <li> En développant des indicateurs de réussite de votre projet</li>{" "}
          </p>
        ),
        icon: "jardin.png",
        border: false,
      },
    ],
  },
  {
    title: "Optimiser votre logistique",
    key: "logistique",
    image: "pika",
    item: [
      {
        title: "Gestion thermique et énergétique",
        key: "energie",
        descrition: (
          <p>
            Si les consommations énergétiques de votre organisation ne sont pas
            responsables de façon directe d’émissions de GES, la production de
            ces énergies entraine quant à elle un rejet de GES, et notamment de
            CO₂.
            <br />
            <br />
            <em>
              <li>
                {" "}
                Dans l’optique d’une réduction de ces émissions, PractiGREEN
                vous conseille dans la gestion de votre espace de travail, afin
                d’optimiser le chauffage et le refroidissement de vos
                installations et l’utilisation de vos ressources électriques.
              </li>
              <br />
              <li>
                {" "}
                En parallèle, l’optimisation de vos espaces d’activités
                permettra d’améliorer le confort des employés, tout en assurant
                la mise en place de solutions de développement durable et
                soutenable.
              </li>
              <br />
              <li>
                {" "}
                Ces solutions participeront à impliquer vos parties prenantes
                dans votre stratégie environnementale et réduire l’empreinte
                carbone de votre organisation.
              </li>
              <br />
            </em>
          </p>
        ),
        icon: "energie.png",
        border: false,
      },
      {
        title: "Optimisation, gestion & revalorisation des déchets",
        key: "dechets",
        descrition: (
          <p>
            En moyenne, 4,6 tonnes de déchets ont été produits en France par
            habitant en 2016 (ADEME), dont une majeure partie par les
            entreprises (0,7 tonnes ; hors BTP) et les entreprises du BTP (3,4
            tonnes).
            <br /> <br />
            Il est nécessaire pour la sauvegarde de notre environnement de
            réduire la quantité des déchets produits et envoyés en centre
            d’enfouissement. <br />
            <br />
            <em>
              <li> Pour cela, PractiGREEN vous accompagne afin de :</li>
            </em>
            <li>Diagnostiquer vos besoins</li>
            <li>Analyser et optimiser vos pratiques</li>
            <li>
              Identifier vos principales sources d’émissions de déchets et leur
              devenir
            </li>
            <br />
            <br />
            <em>
              <li>
                PractiGREEN travaille à estimer et réduire vos différentes
                catégories de déchets :
              </li>
            </em>
            <li>Alimentaires</li>
            <li>Plastiques</li>
            <li>Déchets verts</li>
            <li>…</li>
            <br />{" "}
            <em>
              <li>
                Enfin, dans le respect de vos besoins et pratiques, PractiGREEN
                peut vous proposer une gestion vertueuse de vos déchets en
                optimisant leurs fins de vie :<br />
              </li>
            </em>
            <li>Recyclage</li>
            <li>Consigne</li>
            <li>Mutualisation des déchets</li>
            <li>… </li>
            Ces pratiques vertueuses participent à la création d’une gestion et
            d’une économie circulaire au cœur de votre activité.{" "}
          </p>
        ),
        icon: "dechets.png",
        border: true,
      },
      {
        title: "Évaluation et optimisation de la mobilité et des transports",
        key: "mobilite",
        descrition: (
          <p>
            L'objectif de <em>PractiGREEN</em> est de créer un écosystème
            logistique durable et soutenable, adapté à votre activité afin
            d’établir des modes de fonctionnement respectueux de l’environnement
            :
            <br />
            <li>Un écosystème adapté aux besoins de votre entreprise</li>
            <li>
              Un écosystème répondant à vos exigences de productivité &
              d’efficacité
            </li>
            <li>Un écosystème qui puisse satisfaire vos parties prenantes.</li>
            <br />{" "}
            <em>
              <li>
                PractiGREEN propose de dimensionner
                l’entreprise et les besoins en logistique afin de définir une
                stratégie logistique adaptée.
              </li>
            </em>
            <br />{" "}
            <em>
              <li>
                Afin de répondre à la stratégie adoptée, PractiGREEN définit,
                évalue et optimise :<br />
              </li>
            </em>
            <li>
              La structuration des lieux de stockages de matières premières et
              des produits finis (zonage, rangement, signalétique,
              cross-docking, automatisation, etc.)
            </li>
            <li>
              Vos modalités de transports internes (transport et venue des
              collaborateurs sur le site de travail,...)
            </li>
            <li>
              Vos modalités de transports externes (modalités de transport des
              matières premières, des produits finis, optimisation des
              transports et des itinéraires)
            </li>
          </p>
        ),
        icon: "mobilite.png",
        border: false,
      },
    ],
  },
  {
    title: "Développer votre stratégie RSE",
    key: "RSE",
    image: "vert",
    item: [
      {
        title: "Stratégie, évaluation et optimisation RSE",
        key: "strategie",
        descrition: (
          <p>
            La Responsabilité Sociétale des Entreprises est l’intégration
            volontaire par les entreprises des préoccupations sociales et
            environnementales à leurs activités commerciales/industrielles.
            <br />
            <br /> Le développement d’une démarche RSE forte est de plus en plus
            demandé par les clients et les gouvernements. De plus, une
            déclaration de performance extra-financière (ou rapport RSE) est
            obligatoire pour les entreprises de plus de 500 employés. <br />
            <br />
            <em>
              {" "}
              <li>
                PractiGREEN s'appuie notamment sur la norme ISO 26000, référence
                mondiale en matière de développement RSE des entreprises, pour
                mettre en place, avec vous, une stratégie de développement de
                votre RSE qui se veut holistique.
              </li>
              <br />
              <li>
                La démarche de PractiGREEN intègre les 7 grandes questions de la
                norme ISO 26000 à savoir les questions environnementales,
                sociales et de gouvernance.
              </li>
              <br />
              <li>
                {" "}
                PractiGREEN vous permet de communiquer sur vos objectifs et vos
                réalisations tout en prenant une longueur d’avance sur
                l’évolution des réglementations.
              </li>
              <br />
              <li>
                PractiGREEN évalue votre positionnement actuel en termes de RSE
                afin de construire à vos côtés une stratégie pour renforcer et
                optimiser vos démarches.
              </li>
            </em>
          </p>
        ),
        icon: "RSE.png",
        border: false,
      },
      {
        title: "Proposition, évaluation et notation d’indicateurs RSE",
        key: "indicateurs",
        descrition: (
          <p>
            PractiGREEN développe à vos côtés des indicateurs environnementaux,
            sociaux et de gouvernance qui vous permettent de suivre l’évolution
            de vos performances en matière de RSE.
            <br />
            <br /> Ces indicateurs vous permettent de :
            <li> Communiquer sur votre engagement RSE</li>
            <li>
              Impliquer vos employés et toutes les parties prenantes dans votre
              démarche
            </li>
            <br />
            <em>
              <li>
              À travers cette démarche, PractiGREEN vous permet de&nbsp;:
              </li>
            </em>
            <li>Vous positionner sur votre marché concurrentiel</li>
            <li>Fédérer vos parties prenantes</li>
            <li>Recruter des talents engagés</li>
          </p>
        ),
        icon: "stratRSE.png",
        border: true,
      },
      {
        title: "Plan RSE & calendrier RSE",
        key: "plan",
        descrition: (
          <p>
            Organiser sa stratégie RSE passe avant tout par la définition et la
            planification d’objectifs ambitieux et réalisables.
            <br />
            <br />
            <em>
              <li>
                PractiGREEN met en place avec vous, un planning d’objectifs en
                fonction de vos besoins, de votre évolution et de votre
                stratégie de développement durable. Le but de ce calendrier RSE
                est de vous projeter sur les mois et les années à venir afin de
                mener à bien votre stratégie et remplir vos objectifs de
                développement.
              </li>
              <br />
              <li>
                PractiGREEN vous accompagne dans la réalisation et la
                communication de ces objectifs.
              </li>
            </em>
          </p>
        ),
        icon: "planRSE.png",
        border: false,
      },
    ],
  },
];
