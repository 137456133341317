import "../../styles/style.css";
import React from "react";
import { blog } from "../../datas/blog";
import { HashLink as Link } from "react-router-hash-link";

function Blog() {
  return (
    <>
      <div className="title center " id="engagements">
        <p id="equipe">NOS ARTICLES</p>
      </div>

      <div className='article blog' style={{display:'flex'}}>
        {blog.map((article) => (
          <Link
            style={{ display: "block", margin: "1rem 0" }}
            to={`/Blog/${article.key}.html`}
            key={article.key}
          >
          <div className='little-article'>
            <img src={article.imageResume} alt="photo de Sam Jotham Sutharson" />
            <div className='fond-hexagon'> </div>
            <h1>{article.title}</h1>
          </div>
         
          
          </Link>
        ))}
      </div>
      <div style={{height:'500px'}}> </div>

    </>
  );
}

export default Blog;
