export const partenaires = [
  {
    key: "1",
    lien: "https://www.narrau.com​",
    icon: "narrau.png",
    alt:"logo de NARRAU"
  },
  {
    key: "2",
    lien: "https://prllx.fr​",
    icon: "parallax.png",
    alt:"logo de Parallax"
  },
  {
    key: "3",
    lien: "https://www.sorbonne-universite.fr/offre-de-formation/preparer-son-projet-professionnel/entreprendre-et-intraprendre/pepite-sorbonne",
    icon: "pepite SU.png",
    alt:"logo de Pépite"
  },
  {
    key: "4",
    lien: "https://www.humalis.fr​",
    icon: "Humalis.png",
    alt:"logo de Humalis"
  },
  {
    key: "5",
    lien: "http://ed398.sorbonne-universite.fr/fr/index.html",
    icon: "GRNE.jpeg",
    alt:"logo de Géosciences, Ressources Naturelles et Environnement École doctorale 398"
  },
  {
    key: "6",
    lien: "https://www.linkedin.com/in/carouyer/",
    icon: "LogoHygeia.jpg",
    alt:"Logo Hygeia"
  },
];
